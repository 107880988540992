import FormObject from './form_input'
import {Box, Typography} from '@material-ui/core'

export default function AppMain({clientInfo, data, channel}) {
    return (
        <Box >
            <FormObject client={clientInfo} data={data} channel={channel}/>
        </Box>
    )
}
