import React from 'react'
import AppHeader from '../header/app_header'
import {useStyles} from '../../styles/styles'
import { Box, Typography } from '@material-ui/core';

export default function MessageDisplayer({title, subtitle, data}) {
    
    const classes = useStyles();

    return (

        <>
            <Box
                className={classes.segmentbox}>
                <Typography variant="h4" className={classes.typobold}>{`${title}`}</Typography>
                <Typography variant="h5" className={classes.typolight}>{`${subtitle}`}</Typography>
            </Box>
        </>
    )
}
