import {useState} from 'react'
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography} from '@material-ui/core'

export default function ModalAlert({alertParams, setalertParams}) {

    function onCloseHandler(){
        setalertParams([false, "", ""]);
    }
    
    return (
        <Dialog open={alertParams[0]} onClose={onCloseHandler}>
            <DialogTitle>{alertParams[1]}</DialogTitle>
            <DialogContent>
                <Typography>
                    
                    {alertParams[2] && alertParams[2].split("\n").map((text, index)=>{
                        return <p key={index}>{text}</p>
                    })}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setalertParams([false, "", ""])}} color="secondary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}
