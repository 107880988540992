import { Button, Box, Typography, TextField, CircularProgress} from '@material-ui/core'
import React from 'react'
import {useState, useEffect} from 'react';
import ModalPin from "../modals/modal_pin";
import ModalAlert from "../modals/modal_alert"
import ModalErrorEmail from "../modals/modal_erroremail"
import {runApiRequest} from '../../Utils/apiUtils'
import {isValid, formatPhone} from '../../Utils/formatUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome'
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import { Redirect } from 'react-router-dom';
import MessageDisplayer from '../thank-page/message_displayer';
import {useStyles} from '../../styles/styles'

library.add(faTimes, faCheck);

const inputAllowed = {
    //"memberId": "Member ID",
    "firstName": "First name",
    "lastName": "Last name",
    "dob": "Date of birth",
    "address": "Address",
    "phone": "Phone",
    "email": "Email",
    "zipCode": "Zip code",
}
const category_type = {
    //"policyHolder": "Policy Holder",
    //"responsibleParty":"Resposible Party",
    "patient": "Patient"
}
const inputType = {
    "memberId": "text",
    "firstName": "text",
    "lastName": "text",
    "dob": "date",
    "address": "text",
    "phone": "tel",
    "email": "email",
    "zipCode": "text"
}
const helperTextMessages = ["*Required", "Incorrect formart"]
var policyShow = false;
var firstExec = true;
let policyToSend = {};

export default function FormObject({client, data, channel}){
    //* state for the form component
    const [policymember, setpolicymember] = useState({});
    const [helperPolicy, sethelperPolicy] = useState({});
    const [redirect, setredirect] = useState(false);
    const [formState, setformState] = useState(false);
    //* state for the modal pin component
    //const [openSendPin, setopenSendPin] = useState(false);
    //const [pinText, setpinText] = useState("");
    const [responseVali, setresponseVali] = useState(null);
    //* state for error email modal
    const [errorEmailParams, seterrorEmailParams] = useState([false, ""]);
    //* state for custom alert modal
    const [alertParams, setalertParams] = useState([false, "", ""]);
    //* state for loading animation
    const [loading, setloading] = useState(false);
    //const [timePinSent, settimePinSent] = useState(null);

    let formStruct = [];
    let inputsComp = [];

    const classes = useStyles();
    /**
     * * Method Already refactored
     */
    async function sendHandler(e) {
        e.preventDefault();
        let errorFields = [];
        let formPrepared = false;

        for(let fkey of Object.keys(category_type)){
            forEachObject(inputAllowed, function(obj,  currentkey){
                if(helperPolicy[currentkey]){
                    formPrepared = true;
                    errorFields.push(`${inputAllowed[currentkey]}: ${helperPolicy[currentkey]}`)
                }
            });
        }
        if(formPrepared == false){
            /* if(!pinText){
                setopenSendPin(true);
            }else{
                let time = {"hour":  new Date().getHours(), "minute": new Date().getMinutes()}
                if(time.hour == timePinSent.hour && time.minute - timePinSent.minute < 15){
                }
            } */
            setformState(true);
            setloading(true);
            policyToSend = policymember;
            policyToSend.channel = channel;
            policyToSend.flag = false;
            setresponseVali(await runApiRequest(policyToSend, process.env.REACT_APP_ROUTE_VALIDATE));
        }else {
            setalertParams([true, "Input Error", `fields with errors:\n${errorFields.join("\n")}`]);
        }
    }

    /**
     * * Method Already refactored
     */
    function inputChangeHandler(e){
        let tempmember = {...policymember};
        let namesplited = e.target.name.split(' ');
        for(let currentkey of namesplited){
            console.log(isValid(tempmember[currentkey], e.target.value, currentkey))
            if(isValid(tempmember[currentkey], e.target.value, currentkey)){
                let inserting_value = e.target.value;
                if(currentkey == "phone"){
                    inserting_value = formatPhone(inserting_value);
                }else if(currentkey == "dob"){
                    if(inserting_value.length > 10){
                        inserting_value = tempmember[currentkey];
                    }
                }else if(currentkey == "zipCode"){
                    inserting_value = inserting_value;
                }
                tempmember[currentkey] = inserting_value
                updateHelper(currentkey, tempmember[currentkey]);
            }
        }
        setpolicymember(tempmember)

    }

    /**
     * * Method Already refactored
     */
    function updateHelper(key, value) {
        let tempmember = {...helperPolicy};
        let helperText = errorFormatmanager(key, value);
        tempmember[key] = helperText;
        sethelperPolicy(tempmember)
    }

    /**
     * * Method Already refactored
     */
    function errorFormatmanager(type, value) {
        if(value != ""){
            if(type == "phone"){
                let phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
                if(!phoneRegex.test(value)){
                    return helperTextMessages[1];
                }
            }else if(type == "email"){

                let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
                if(!emailRegex.test(value)){
                    return helperTextMessages[1];
                }
            }else if(type == "dob"){
                let dateVerif = new Date(value);
                let today = new Date();
                if(dateVerif.getFullYear() < today.getFullYear() - 80 || (today - dateVerif) / (1000 * 3600 * 24 * 365) < 1){
                    return helperTextMessages[1];
                }
            }else if(type == "zipCode"){
                let zipRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
                if(!zipRegex.test(value)){
                    return `${helperTextMessages[1]}. Formats allowed: 00000 or 00000-0000`;
                }
            }
        }else{
            return helperTextMessages[0];
        }
        return "";
    }

    /**
    * * Method Already refactored
    */
    function initformatInput(){
        let tempmember = {...client};
        forEachObject(tempmember, function(obj, currentkey){
            if(inputAllowed.hasOwnProperty(currentkey)){
                if(currentkey == "dob"){
                    obj[currentkey] = new Date().toISOString().split("T")[0];
                }else if(currentkey !== "firstName"){
                    obj[currentkey] = "";
                }
            }
        });
        setpolicymember(tempmember);
    }

    /**
     * * Method Already refactored
     */
    function initHelperText(){
        let tempmember = {...client};
        forEachObject(tempmember, function(obj, currentkey){
            if(inputAllowed.hasOwnProperty(currentkey)){
                return obj[currentkey] = errorFormatmanager(currentkey, policymember[currentkey]);
            }
        });
        sethelperPolicy(tempmember);
    }

    /**
     * * General iterator
     */
    function forEachObject(obj, funct, keyHistory = []) {
        // the recursive iterator
        var k,
        has = Object.prototype.hasOwnProperty.bind(obj);
        for (k in obj){
            keyHistory.push(k)
            if (has(k)) {
                if(typeof obj[k] == "object"){
                    forEachObject(obj[k], funct, keyHistory);
                } else if(obj[k] != null){
                    funct(obj, k, keyHistory)
                }
            }
            keyHistory.pop()
        }
        return obj;
    };

    useEffect(()=>{
        document.title = `${data.name} Form`
        initformatInput();
    },[])

    useEffect(()=>{
        if(responseVali){
            setloading(false);
            if(responseVali.status == 200){
                if(!responseVali.data["success"] && responseVali.data["value"]["errorFound"]){
                    let errors = [];
                    forEachObject(responseVali.data.value, function (obj, currentkey) {
                        if (currentkey === 'valid' && !obj[currentkey]) {
                            errors.push(`${inputAllowed.email} ${obj.message}: ${obj.value}` );
                            let tempmember = {...helperPolicy};
                            tempmember.email = obj.message;
                            sethelperPolicy(tempmember);
                        }
                    });
                    if(errors.length > 0){
                            seterrorEmailParams([true, errors]);
                            //seterrorIndicator(errors);
                    }
                } else if(responseVali.data["success"]){
                    //setresponseVali(null);
                    setalertParams([true, "Form notification", "Form sent correctly"]);
                    setTimeout(() => {
                        setredirect(true);
                    }, 5000);
                }else if(!responseVali.data["success"]){
                    setalertParams([true, "Form error", "Something went wrong"]);
                    setresponseVali(null);
                    setformState(false);
                }
            } else {
                setalertParams([true, "Form error", "Something went wrong, please try again later."]);
                setformState(false);
            }
        }

    },[responseVali])

    useEffect(() => {
        if(Object.keys(policymember).length > 0 && firstExec){
            initHelperText();
            firstExec = false
        }
    }, [policymember])

    /**
    * * Method Already refactored
    */
    for(let fkey of Object.keys(category_type)){
        inputsComp = [];
        inputsComp.push(<Typography key={`title ${category_type[fkey]}`} variant="h5" align="center" >{category_type[fkey]}</Typography>)
        forEachObject(inputAllowed, function(obj, currentkey){
            if(policymember.hasOwnProperty(currentkey)){
                inputsComp.push(
                    <TextField
                        disabled={formState}
                        key={`${fkey} ${currentkey}`}
                        name={`${currentkey}`}
                        label={obj[currentkey]}
                        className={classes.textfield}
                        type={inputType[currentkey]}
                        value= {policymember[currentkey]}
                        onChange={inputChangeHandler}
                        helperText={helperPolicy[currentkey]}
                        InputProps={{
                                endAdornment: (
                                        helperPolicy[currentkey]
                                        ? <FontAwesomeIcon className={classes.iconError} icon="times" />
                                        : <FontAwesomeIcon className={classes.iconCorrect} icon="check" />
                                ),
                            }}
                        InputLabelProps={currentkey === "dob" ? { shrink: true } : null}
                        />)
            }
        });
        if(inputsComp.length > 0){
            formStruct.push(<Box
                key={`box ${fkey}`}
                className={classes.segmentbox}
                >{inputsComp}</Box>)
        }
    }
    if(formStruct.length > 0){
        formStruct = ([
            <form
            key="form tag"
            action=""
            ><Box
            key="Box Form"
            className={classes.formbox}
            >
            {formStruct}
        </Box></form>]
        )
        formStruct.push(
            <Box
            key="Button Box"
            textAlign="center">
            <Button
                disabled={formState}
                key="button send"
                variant="outlined"
                size="large"
                color="primary"
                className={classes.button}
                onClick={sendHandler}>Send</Button>
            </Box>
        )
        formStruct.push([
            /* <ModalPin
                key="modal send"
                policymember={policymember}
                openSendPin={openSendPin}
                setopenSendPin={setopenSendPin}
                responseVali={responseVali}
                setresponseVali={setresponseVali}
                loading={loading}
                setloading={setloading}
                pinText={pinText}
                setpinText={setpinText}
                />, */
            <ModalAlert
                key="modal alert"
                alertParams={alertParams}
                setalertParams={setalertParams}/>,
            <ModalErrorEmail
                key="modal erroremail"
                errorEmailParams={errorEmailParams}
                seterrorEmailParams={seterrorEmailParams}
                policyToSend={policyToSend}
                setresponseVali={setresponseVali}
                setloading={setloading}
                setformState={setformState}
                />,
            loading && <CircularProgress
                key="loading bar"
                style={{
                    position: 'absolute', left: '50%', top: '50%'
                }}

            />
        ])
    }
    if(redirect){
        document.title = "Thank you!"
        return <MessageDisplayer
            data={data}
            title="Thanks for filling the form"
            subtitle="The form has been sent succesfully now you can close this window"
        />
    }else{
        return formStruct
    }
}
