import CryptoJS from 'crypto-js';
import axios from "axios"

export async function runApiRequest(patid, route) {
    const hmac_user = process.env.REACT_APP_HMAC_USER;
    const hmac_secret = process.env.REACT_APP_HMAC_SECRET;
    const expire = 60;
    const dateNow = new Date().toUTCString();
    const apikey = process.env.REACT_APP_APIKEY;
    const host = process.env.REACT_APP_HOST;
    const headers = {
        "x-date": `${dateNow}`,
        "host": `${host}`,
        "apiKey": `${apikey}`
    }
    const algorithm ="sha512";
    const url = process.env.REACT_APP_BASEURL;
    const stringToSign = Object.entries(headers).map(([index, value]) => `${index}: ${value}`).join('\n');
    const keyToSign = Object.keys(headers).join(" ");
    const data_req = patid
    const hashmac = CryptoJS.HmacSHA512(stringToSign, hmac_secret).toString(CryptoJS.enc.Base64);
    const authorization = `hmac username="${hmac_user}",algorithm="hmac-${algorithm}",headers="${keyToSign}",signature="${hashmac}"`.toString();

    var response = null;
    await axios({
        method: "POST",
        url: `${url}${route}`,
        data: data_req,
        headers: {
        'Content-Type': 'application/json',
        "x-date": dateNow,
        "apiKey": apikey,
        "authorization": authorization},
    }).then((res)=>{
        response = res;
        return response;
    }).catch((err)=>{
        response = err.response;
        return response;
    });
    return response;
}
