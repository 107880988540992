function isValid(prev, value, type) {
    if(prev.length > value.length)
        return true
    if(type == "firstName" || type == "lastName")
        return (/^[a-zA-Z\s]+$/).test(value)
    if(type == "zipCode")    
        return (/^[0-9\-]+$/).test(value)
    if(type == "memberId")    
        return (/^[a-zA-Z0-9\-]+$/).test(value)
    if(type == "email")
        return (/^[a-zA-Z0-9\@\.]+$/).test(value)
    if(type == "phone")
        return (/^[0-9\(\)\-\s]+$/).test(value)
    return true
}

function formatPhone(value) {
    // return nothing if no value
    if (!value) return value; 
    
    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length; 
    if (cvLength < 4) return currentValue; 
    
    if (cvLength < 7) return `(${currentValue.slice(0, 3)})${currentValue.slice(3)}`; 
    
    return `(${currentValue.slice(0, 3)})${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`; 
}

//TODO usar esta funcion en el sendHandler
function formatDate(date) {
    var year = new Date(date).getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return month + '/' + day + '/' + year;
}

export {isValid, formatDate, formatPhone}