import AppHeader from './component/header/app_header'
import AppMain from './component/main/app_main'
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {BrowserRouter as Router, Route, Switch, useParams} from 'react-router-dom';
import {useState, useEffect} from 'react';
import {runApiRequest} from './Utils/apiUtils'
import { Box, Typography, CircularProgress } from '@material-ui/core';
import ErrorPage from './component/http_errors/error_page';

function StartApp({channel}) {
    const [response, setresponse] = useState(null);
    const {id} = useParams();
    let request = {"formId": id}
    useEffect( () => {
        initFetch();
    }, []);
    async function initFetch(){
        setresponse(await runApiRequest(request, process.env.REACT_APP_ROUTE_GET))
    }
    if(response){
        if(response.status === 200){
        if(response.data["success"]){
            if(response.data["value"]){
            const entrepriseData = {
                "name": response.data["value"]["theme"]["title"]
            }
            const theme_pallete = response.data["value"]["theme"]
            const theme = createMuiTheme(theme_pallete);
            let clientInfo = response.data["value"]["clientInfo"];
            clientInfo["formID"]= id;
            if(clientInfo){
                return(
                <ThemeProvider theme={theme}>
                    <AppHeader data={entrepriseData}/>
                    <AppMain
                        clientInfo={clientInfo}
                        data={entrepriseData}
                        channel={channel}/>
                </ThemeProvider>
                )
            }
            }
        }
        }else{
        return(
            <Box>
            <ErrorPage err={response}/>
            </Box>
        )
        }
    } else {
        return(
        <CircularProgress
            style={{
                position: 'absolute', left: '50%', top: '50%'
            }}
            />
        )
    }

    }

    function App() {
    const defaultStatus = {"data":{
        "code": 404,
        "message":"The page your are looking for, doesn't exist."
    }};
    return (
        <Router>
        <Switch>
            <Route
                exact
                path="/sms/:id"
                >
                <StartApp channel="sms"/>
            </Route>
            <Route
                exact
                path="/email/:id"
                >
                <StartApp channel="email"/>
            </Route>
            <Route>
                <ErrorPage err={defaultStatus}/>
            </Route>
        </Switch>
        </Router>
    );
}



export default App;
