import {useState} from 'react'
import {Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import {isValid} from '../../Utils/formatUtils'
import {runApiRequest} from '../../Utils/apiUtils'

export default function ModalErrorEmail({
        errorEmailParams,
        seterrorEmailParams,
        policyToSend,
        setresponseVali,
        setloading,
        setformState
    }) {

    async function forceSendEmailHandler(){
        setloading(true);
        policyToSend.flag = true;
        onCloseHandler()
        setresponseVali(await runApiRequest(policyToSend, process.env.REACT_APP_ROUTE_VALIDATE));
    }

    function fixEmail(){
        setformState(false);
        onCloseHandler();
    }
    function onCloseHandler(){
        seterrorEmailParams([false, ""]);
    }
    return (
        <Dialog
            open={errorEmailParams[0]}
            onClose={onCloseHandler}
            disableBackdropClick
            disableEscapeKeyDown
            >
            <DialogTitle>Error Email</DialogTitle>
            <DialogContent>
                <p>We have detected errors in these emails:</p>
                {errorEmailParams[1] && errorEmailParams[1].map((text, index)=>{
                        return <p key={index}>{text}</p>
                    })}
            </DialogContent>
            <DialogActions>
                <Button onClick={fixEmail} color="primary">
                    Fix Email
                </Button>
                <Button onClick={forceSendEmailHandler} color="secondary">
                    Send Anyway
                </Button>
            </DialogActions>
        </Dialog>
    )
}
