import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles((theme) =>{
    return ({
    //Header style
    header:{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    //Http error styles
    httpErrorBox:{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.light,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap"
    },
    httpErrorInnerBox:{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        flexGrow: "1",
    },
    //space man svg style
    svgSpaceMan:{
        flexGrow: "1",
        "stroke": theme.palette.primary.dark,
    },
    // h1, h2, h3 style used for http error pages
    typobold:{ 
        "margin": ".5rem 0",
        "font-weight": "bold",
        padding: "0 5%",
        color: theme.palette.primary.dark,
    },
    typolight:{
        "margin": ".5rem 0",
        "font-weight":"light",
        padding: "0 5%",
        color: theme.palette.primary.dark,
    },
    // formulary styles
    formbox:{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.light,
        minHeight: "100%",
        display: "flex" ,
        flexDirection: "row",
        flexWrap: "wrap"
    },
    segmentbox:{
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "2rem"
    },
    //style for elements
    textfield:{
        marginTop: "1.5rem",
        minWidth: "75%",
        maxWidth: "10%",
        '& .MuiFormHelperText-root': {
            color: theme.palette.primary.light,
            "font-size": "0.8rem",
            "font-weight": "bold"
        },
        '& .MuiInputBase-input': {
            color: theme.palette.secondary.contrastText,
        },
        '& .MuiInput-underline:hover:before': {
            borderBottom: `3px solid ${theme.palette.primary.light}`,
        },
        '& .MuiInput-underline:after': {
            borderBottom: `3px solid ${theme.palette.primary.main}`,
        },
        

    },
    button:{
        marginTop:"3rem",
        marginBottom:"2rem",
        maxWidth: '10%', 
        maxHeight: '10%', 
        minWidth: '30%', 
        minHeight: '30%'
    },
    // icons style
    iconError:{
        color:"red"
    },
    iconCorrect:{
        color:"green"
    }
})},{index: 1});

