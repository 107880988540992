import { Box, Typography } from '@material-ui/core'
import { React, useEffect } from 'react'
import Spaceman from './spaceman'
import {useStyles} from '../../styles/styles'

export default function ErrorPage({err}) {
    let status = "Error Space";
    if(err["data"]["code"])
        status = err["data"]["code"].toString();
    else if(err["status"])
        status = err["status"].toString();

    const httpresponses = {
        "400":{
            "errorType":"Bad Request",
            "message":"Request syntax invalid"
        },
        "401":{
            "errorType":"Unauthorized",
            "message":"Authentication needed"
        },
        "403":{
            "errorType":"Forbidden",
            "message":"Fordidden access to this information"
        },
        
        "404": {
            "errorType":"Not found",
            "message": "The page your are looking for, doesn't exist."
        },
        "405":{
            "errorType":"Method Not Allowed",
            "message":"Unable to use the current method"
        },
        "406": {
            "errorType":"Not acceptable",
            "message": "Couldn't find data for the criteria."
        },
        "407":{
            "errorType":"Proxy Authentication Required",
            "message":"Proxy must be authenticated"
        },
        "408":{
            "errorType":"Request Timeout",
            "message":"Request took too long"
        },
        "500":{
            "errorType":"Internal Server Error",
            "message":"Server is having issues"
        },
        "501":{
            "errorType":"Not Implemented",
            "message":"Method not supported"
        },
        "502":{
            "errorType":"Bad Gateway",
            "message":""
        },
        "503":{
            "errorType":"Service Unavailable",
            "message":"Server not ready to handle request"
        }
    }
    useEffect(() => {
        document.title = `${httpresponses[status]["errorType"]}`;
    }, [])
    const classes = useStyles();
    return (
        <Box
            className={classes.httpErrorBox}
            >
            <Spaceman/>

            <Box
                className={classes.httpErrorInnerBox}>
                <Typography variant="h3" className={classes.typobold}>{`${status}`} </Typography>
                <Typography variant="h4" className={classes.typobold}>{`${httpresponses[status]["errorType"]}`}</Typography>
                <Typography variant="h5" className={classes.typolight}>{err["data"]["message"] ? `${err["data"]["message"]}` : `${httpresponses[status]["message"]}`}</Typography>
            </Box>

        </Box>
    )
}
