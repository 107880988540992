import React from 'react';
import {CardHeader, createStyles, makeStyles} from '@material-ui/core';
import {useStyles} from '../../styles/styles'

export default function AppHeader(props) {
    
    const classes = useStyles();
    return (
        <CardHeader 
            title={`${props.data["name"]} Form`}
            className={classes.header}
            />
    )
}
